'use strict';

var slickHelpers = require('../../../../app_storefront_core/cartridge/js/slickSliderHelpers');

var sheplersApp = {
    init: function () {
        slickHelpers.init();
    }
};

//initialize app
$(document).ready(function () {
    sheplersApp.init();
});